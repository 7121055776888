
const $likeButtons = $('.comment-reactions').find('.like');

$likeButtons.click(function(e) {
    e.preventDefault();

    const $likeButton = $(this);
    const $reactions = $likeButton.parents('.comment-reactions');
    const $likeCount = $reactions.find('.like-count')

    let data = new FormData();
    data.append('action', 'like_comment');
    data.append('comment_id', $likeButton.data('commentId'));
    data.append('status', $likeButton.hasClass('liked') ? -1 : 1);

    if ($likeButton.hasClass('liked')) {
        $likeButton.removeClass('liked');
    } else {
        $likeButton.addClass('liked');
    }

    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const results = data.responseJSON;
            const likes = results.likes;

            $likeCount.html(likes.length);

            if (results.likes.length > 0) {
                $reactions.addClass('has-likes');
            } else {
                $reactions.removeClass('has-likes');
            }
        }
    });

})