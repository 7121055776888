const $reactions = $('.post-reactions');
const $likeButton = $reactions.find('.like');
const $likes = $reactions.find('.likes');
const $likeCount = $reactions.find('.like-count')

$likeButton.click(function(e) {
    e.preventDefault();

    let data = new FormData();
    data.append('action', 'like_post');
    data.append('post_id', $likeButton.data('postId'));
    data.append('status', $likeButton.hasClass('liked') ? -1 : 1);

    if ($likeButton.hasClass('liked')) {
        $likeButton.removeClass('liked');
    } else {
        $likeButton.addClass('liked');
    }

    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const results = data.responseJSON;
            const likes = results.likes;
            let html = '';

            if (likes.length > 1) {
                html = likes.slice(0, -1).join(', ') + ' og ' + likes.slice(-1).join('');
            } else {
                html = likes.join('');
            }

            $likes.find('.users span').html(html);
            $likeCount.find('span').html(likes.length);

            if (results.likes.length > 0) {
                $reactions.addClass('has-likes');
            } else {
                $reactions.removeClass('has-likes');
            }
        }
    });

})